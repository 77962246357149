export function Loader() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff9c4",
        opacity: 0.5,
        height: "100vh",
      }}
    >
      <div
        style={{
          color: "#f88f23",
          height: "100px",
          width: "auto",
        }}
      >
        <img
          src="./logo-header.svg"
          style={{ fill: "#f88f23" }}
          width="100px"
        />
      </div>
    </div>
  );
}
